import BaseButton from "../base/button";
import cn from "classnames";

import "./scan-row.scss";

const DeleteButton = ({ onClick }: { onClick: () => void }) => (
  <BaseButton className="scan-row__delete-btn" onClick={onClick}>
    X
  </BaseButton>
);

interface ScanRowItem {
  text: string;
  err?: boolean;
  errMsg?: string;
}

interface ScanRowProps {
  primary: ScanRowItem;
  secondary: ScanRowItem;
  onClick: (id: string) => void;
}

export const ScanRow = ({ primary, secondary, onClick }: ScanRowProps) => {
  const { text: lText, err: lErr, errMsg: lErrMsg } = primary;

  const { text: rText, err: rErr, errMsg: rErrMsg } = secondary;

  const lClass = cn("scan-row__scan-input", {
    valid: lText && !lErr,
    error: lErr,
  });
  const rClass = cn("scan-row__scan-input", {
    valid: rText && !rErr,
    error: rErr,
    inactive: !lText || lErr,
  });
  const checkClass = cn("scan-row__checkmark", {
    valid: lText && rText && !lErr && !rErr,
  });

  return (
    <section className="scan-row__wrapper">
      <section className="scan-row__input-row">
        <div className={lClass}>
          {lText}
          {lText ? <DeleteButton onClick={() => onClick(lText)} /> : <></>}
        </div>
        <div className={rClass}>
          {rText}
          {rText ? <DeleteButton onClick={() => onClick(rText)} /> : <></>}
        </div>
        <div className={checkClass}>✓</div>
      </section>
      <section className="scan-row__error-row">
        <div className="scan-row__scan-error">{lErrMsg}</div>
        <div className="scan-row__scan-error">{rErrMsg}</div>
      </section>
    </section>
  );
};

export default ScanRow;
